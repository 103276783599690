
// Libraries
import * as React from 'react'
import {graphql} from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'

const AboutPage = ({data}) => {
	const pageContent = data?.contentManagement?.content

	return (<Layout className="nav-blue-all">
		<Seo title="About Concerts for Carers"/>
		<section className="generic-template">
			<div className="container generic-template--parent">
				<div className="container generic-template--container">
					<h1 className="generic-template__title">{pageContent?.title}</h1>
					<div className="content cms" dangerouslySetInnerHTML={{ __html: pageContent?.content }} />
				</div>
			</div>
		</section>
	</Layout>)
}

export default AboutPage

export const query = graphql`
{
	contentManagement(slug: {eq: "about"}) {
		id
		content {
			title
			content
		}
	}
}
`
